<template>
  <div class="quiz_manage_area">
    <div class="dashboard">

      <div class="manage_title_area">
        <div class="search_area">
          <input type="text" class="search_text" placeholder="내 퀴즈를 검색하세요." v-on:keyup.enter="searchQuiz" :value="searchText">
          <button type="submit" class="search_btn" @click="searchQuiz">
            <i class="search_icon"></i>
          </button>
        </div>

        <button class="add_btn" @click="openWriteModal">퀴즈 등록</button>
      </div>
      <div class="sub_div">

        <div class="my_quiz_list_area" @scroll="scrollEvent">
          <div class="top_area">
            <p class="my_quiz_label">내 퀴즈</p>
            <p class="my_quiz_cnt">({{myQuizTotalCnt}}건)</p>
          </div>

          <div class="my_quiz_list">
            <div class="head_area">
              <p class="head_item category">카테고리</p>
              <p class="head_item title">제목</p>
              <p class="head_item answer">답변 수</p>
              <p class="head_item like">좋아요 수</p>
              <p class="head_item delete"></p>
            </div>
            <template v-if="myQuizList.length > 0">
              <template v-for="(myQuizItem, index) in myQuizList" >
                <div class="quiz_item" >
                  <div class="item_category_area" @click="openQuizModal(myQuizItem.quizId)">
                    <span class="sub_category">{{ myQuizItem.categoryName }}</span>
                  </div>
                  <div class="item_title_area" @click="openQuizModal(myQuizItem.quizId)">
                    <p class="title">{{ myQuizItem.title }}</p>
                  </div>
                  <div class="item_answer_area" @click="openQuizModal(myQuizItem.quizId)">
                    <div class="m_answer_cnt_img"></div>
                    <span class="answer_cnt">{{ myQuizItem.answerCnt }}</span>
                  </div>
                  <div class="item_like_area" @click="openQuizModal(myQuizItem.quizId)">
                    <div class="m_like_cnt_img"></div>
                    <span class="like_cnt">{{ myQuizItem.likeCnt }}</span>
                  </div>
                  <div class="item_delete_area">
                    <button class="delete_btn" @click="deleteQuiz(myQuizItem.quizId)"></button>
                  </div>
                </div>
                <div class="border_line"></div>
              </template>
              <div class="quiz_loader_div" v-show="showLoader == true">
                <div class="loader"></div>
              </div>
            </template>
            <template v-else>
              <p class="quiz_list_no_data">등록된 퀴즈가 없습니다.</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer/>

  <WriteModal @closeWriteModal=closeWriteModal v-show="writeModalOn"/>
  <QuizModal @closeQuizModal=closeQuizModal :quizId="quizId" v-if="quizModalOn"/>
</template>
<script>
import apiClient from "@/plugins/apiClient";
import Loader from "@/components/common/Loader";
import WriteModal from "@/components/modal/write/WriteModal";
import QuizModal from "@/components/modal/quiz/QuizModal.vue";
import Footer from "@/components/layout/Footer.vue";
import {toastFail, toastSuccess} from "@/utils/toastUtils";

export default {
  name: 'MyQuizManage',
  components: {Footer, QuizModal, WriteModal, Loader},
  data: () => ({
    myQuizList: [],
    myQuizTotalCnt : 0,
    currentPage: 1,
    searchText: '',
    writeModalOn: false,
    onSleep: false,
    showLoader : false,
    quizModalOn: false,
    quizId: 0
  }),
  created() {
    this.getMyQuizList(1);
  },
  methods: {
    scrollEvent(e) {
      if(this.myQuizList.length == this.myQuizTotalCnt || this.onSleep) {
        this.showLoader = false;
        return false;
      }
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isEnd = scrollHeight === scrollTop + clientHeight;

      if (isEnd) {
        this.onSleep = true;
        this.showLoader = true;
        this.movePage = this.movePage + 1;
        this.getMyQuizList();

        setTimeout(() => {
          this.onSleep = false;
        }, 300);

      }
    },
    getMyQuizList(showLoad) {
      if(showLoad){
        $('.loader_div').show();
      }

      if(this.movePage == null) this.movePage = 1;

      let params = {};
      params.searchText = this.searchText;

      apiClient.get('/api/my-quizzes/' + this.movePage, {params : params}).then(data => {
        let result = data.data.resData;

        if(result.myQuizItemList.length > 0) {
          this.myQuizList.push(...result.myQuizItemList);
          this.myQuizTotalCnt = result.totalCount;

          if (this.myQuizList.length == this.myQuizTotalCnt) {
            this.showLoader = false;
          }

        }
        $('.loader_div').hide();
      });
    },
    deleteQuiz(quizId){
      if(quizId == null || quizId === 0){
        toastFail("삭제 오류 발생하였습니다.");
        return false;
      }

      if(confirm("문제를 삭제하시겠습니까?")){
        $('.loader_div').show();
        apiClient.delete('/api/my-quiz/' + quizId).then(data => {
          if(data.data.resData > 0){
            toastSuccess('삭제 성공하였습니다.');
            this.myQuizList = [];
            this.getMyQuizList(this.currentPage);
          }else{
            toastFail("삭제 실패하였습니다.");
          }
          $('.loader_div').hide();
        })
      }
    },
    searchQuiz(){
      this.myQuizList = [];
      this.myQuizTotalCnt = 0;
      let searchText = $(".search_text").val();

      this.searchText = searchText;
      this.getMyQuizList();
    },
    openWriteModal(){
      this.writeModalOn = true;
    },
    closeWriteModal: function (){
      this.writeModalOn = false;
    },
    openQuizModal(quizId){
      this.quizId = quizId;
      this.quizModalOn = true;
    },
    closeQuizModal: function (){
      this.quizModalOn = false;
    },
  }
}
</script>

<style>
  @import "@/assets/main/manage/MyQuizManage.css";
</style>