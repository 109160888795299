import {toast} from "vue3-toastify";

const toastOption = {
    autoClose: 1000,
    position: 'bottom-center',
    toastStyle: {
        fontSize: '14px',
    }
}

export function toastSuccess(msg){
    toast.success(msg, toastOption);
}

export function toastInfo(msg){
    toast.info(msg, toastOption);
}

export function toastFail(msg){
    toast.error(msg, toastOption);
}