<template>
  <div class="side_bar">
    <div class="logo_area"><div class="logo_img" @click="switchHomePage"/></div>

    <div class="nav_area">
      <div class="nav_tab_area">
        <label class="vc-switch">
          <input type="checkbox" class="vc-switch-input" @change="tabChange" v-if="login">
          <span class="vc-switch-label" data-on="관리 모드" data-off="퀴즈 모드"></span>
          <span class="vc-handle"></span>
        </label>

      </div>
      <div class="nav_sub_area">
        <Category v-show="tabOn === 'category'"></Category>
        <Manage v-show="tabOn === 'manage'"></Manage>
      </div>
    </div>

    <div :class="'profile_area' + (!login ? ' not_login' : '') " v-if="login">
      <div class="profile_img_area" @click="openProfileEditModal">
        <img :src="profileImgUrl" @error="defaultImg"/>
      </div>
      <div class="profile_info_area">
        <p class="profile_name" v-if="login">{{ userName }}</p>
        <p class="profile_edit" v-if="login" @click="openProfileEditModal">프로필 수정</p>
      </div>
      <p class="logout_label" @click="logout" ></p>
    </div>
    <div class="login_area" v-else @click="openLoginModal">
      <div class="profile_img_area">
        <img class="default_img" src="@/resource/img/person.png" alt="기본 프로필 이미지"/>
      </div>
      <div class="login_required_area">
        <p>로그인이 필요합니다!</p>
      </div>
    </div>
  </div>
  <DailyQuizModal @closeDailyQuizModal=closeDailyQuizModal :dailyQuizId="dailyQuizId" :dailyQuizModalOn="dailyQuizModalOn" v-if="dailyQuizModalOn"></DailyQuizModal>
  <RegisterModal @closeRegisterModal=closeRegisterModal @openLoginModal=openLoginModal :registerModalOn="registerModalOn" v-if="registerModalOn"/>
  <ProfileEditModal @closeProfileEditModal="closeProfileEditModal" :profileEditModalOn="profileEditModalOn" @reloadMyInfo="reloadMyInfo" v-if="profileEditModalOn"/>
  <LoginModal @closeLoginModal="closeLoginModal" @openRegisterModal=openRegisterModal v-if="loginModalOn"/>
</template>

<script>

import Category from "@/components/main/sidebar/sub/Category";
import axios from "axios";
import Manage from "@/components/main/sidebar/sub/Manage";
import router from "../../../utils/router";
import DailyQuizModal from "@/components/modal/quiz/DailyQuizModal";
import RegisterModal from "@/components/modal/regist/RegisterModal";
import ProfileEditModal from "@/components/modal/profile/ProfileEditModal";
import LoginModal from "@/components/modal/login/LoginModal";
import {mapActions} from "vuex";
import {toastSuccess} from "@/utils/toastUtils";

export default {
  name: 'SideBar',
  components: {LoginModal, ProfileEditModal, RegisterModal, DailyQuizModal, Manage, Category},
  data: () => ({
    tabOn : 'category',
    login : false,
    userName : '',
    profileImgUrl : '',
    dailyQuizId : 0,
    dailyQuizModalOn: false,
    registerModalOn : false,
    profileEditModalOn : false,
    loginModalOn: false
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    }
  },
  watch:{
    isLoginCheck(){
      if(this.$store.state.auth.isLogin){
        this.login = true;
        this.getUserInfo();
      }else {
        $(".vc-switch-input").click();
        this.tabOn = 'category';
        this.login = false;
      }
    }
  },
  created() {
    if(this.login){
      this.getUserInfo();
    }
  },
  methods: {
    defaultImg(e){
      e.target.src = require('@/resource/img/person.png');
    },
    clickTab(idx){
      this.isOn = idx;
    },
    getUserInfo(){
      axios.get('/api/user').then(data => {
        let dailyQuizId = data.data.resData.dailyQuizId;

        this.userName = data.data.resData.userName;
        this.profileImgUrl = data.data.resData.profileImgUrl;
        this.dailyQuizId = dailyQuizId;

        this.$store.dispatch('setDailyQuizId', dailyQuizId == null ? 0 : dailyQuizId);

        if(dailyQuizId !== null && dailyQuizId !== 0){
          axios.get(`/api/daily-quiz/check/` + dailyQuizId).then(data => {
            if(data.data.resData > 0) {
              this.$store.dispatch('setDailyQuizTodayCheck', true);
            }else{
              this.$store.dispatch('setDailyQuizTodayCheck', false);
            }
          });
        }
      });
    },
    tabChange(){
      if(this.tabOn == '' || this.tabOn == 'manage'){
        this.tabOn = 'category';
      }else{
        this.tabOn = 'manage';
      }
    },
    switchHomePage: function (){
      router.push('/');
      $(".side_bar").css('display', 'none');
      $(".side_bar_overlay").removeClass('on');
    },
    sidebarClose(){
      $(".category_detail").removeClass('active');
      $(".side_bar_overlay").removeClass('on');
    },
    closeDailyQuizModal: function (){
      this.dailyQuizModalOn = false;
    },
    closeRegisterModal: function (){
      this.registerModalOn = false;
    },
    openRegisterModal: function (){
      this.registerModalOn = true;
    },
    closeProfileEditModal: function (){
      this.profileEditModalOn = false;
    },
    openProfileEditModal: function (){
      this.profileEditModalOn = true;
    },
    reloadMyInfo(){
      this.getUserInfo();
    },
    openLoginModal: function (){
      this.loginModalOn = true;
    },
    closeLoginModal: function (){
      this.loginModalOn = false;
    },
    async logout() {
      this.$store.dispatch('logout');
      toastSuccess("로그아웃 되었습니다.");
    }
  }
}
</script>
<style>
  @import "@/assets/common/animation.scss";
  @import "@/assets/main/sidebar/SideBar.css";
</style>