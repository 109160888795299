<template>
  <div class="term_area">
    <div class="term_content_area">
      <div class="term_sub_area">
        <h1 class="term_large_title">이용 약관</h1>

        <p class="term_info">
          본 약관은 WIZLQ(이하 "회사")가 제공하는 WIZLQ(이하 "서비스")의 이용과 관련하여 회사와 사용자 간의 권리, 의무 및 책임 사항을 규정합니다. 서비스를 이용하기 전에 본 약관을 주의 깊게 읽어주시기 바랍니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">1. 용어 정의</h1>

        <p class="term_info">
          1. "서비스"란 회사가 제공하는 모든 온라인 플랫폼 및 관련 서비스를 의미합니다.
        </p>

        <p class="term_info">
          2. "사용자"란 서비스에 접속하여 본 약관에 따라 서비스를 이용하는 자를 의미합니다.
        </p>

        <p class="term_info">
          3. "계정"이란 사용자가 서비스를 이용하기 위해 등록한 닉네임, 로그인 아이디 및 비밀번호의 정보를 의미합니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">2. 약관의 효력 및 변경</h1>

        <p class="term_info">
          1. 본 약관은 사용자가 서비스에 접속하여 동의함으로써 효력이 발생합니다.
        </p>

        <p class="term_info">
          2. 회사는 필요에 따라 약관을 변경할 수 있으며, 변경된 약관은 서비스 내 공지 또는 이메일을 통해 사용자에게 통지합니다. 사용자는 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 계정을 해지할 수 있습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">3. 계정 관리</h1>

        <p class="term_info">
          1. 사용자는 본인의 계정을 타인에게 공유하거나 양도할 수 없습니다.
        </p>

        <p class="term_info">
          2. 계정의 관리 책임은 사용자에게 있으며, 사용자의 과실로 발생한 문제에 대해 회사는 책임을 지지 않습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">4. 개인정보 보호</h1>

        <p class="term_info">
          회사는 사용자의 개인정보를 관련 법령에 따라 보호하며, 자세한 사항은 <span class="term_link" @click="privacy">[개인정보 처리방침]</span>을 참조하시기 바랍니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">5. 서비스 이용</h1>

        <p class="term_info">
          1. 사용자는 서비스 이용 시 관련 법령을 준수해야 하며, 타인의 권리를 침해하거나 불법적인 행위를 해서는 안 됩니다.
        </p>

        <p class="term_info">
          2. 회사는 서비스의 운영 및 관리를 위해 필요한 경우, 사용자의 서비스 이용을 제한하거나 중지할 수 있습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">6. 서비스 제공의 변경 및 중단</h1>

        <p class="term_info">
          1. 회사는 서비스를 지속적으로 제공하기 위해 노력합니다. 다만, 천재지변, 기술적 문제, 정책 변경 등 불가피한 사유로 인해 서비스의 제공이 어려울 경우, 사전 통지 없이 서비스의 전부 또는 일부를 변경하거나 중단할 수 있습니다.
        </p>

        <p class="term_info">
          2. 서비스 변경 또는 중단으로 인한 불이익에 대해 회사는 책임을 지지 않습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">7. 지적 재산권</h1>

        <p class="term_info">
          1. 사용자가 서비스에 게시하는 모든 콘텐츠(글, 사진, 동영상, 댓글 등)에 대한 권리는 해당 사용자에게 있습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">8. 면책 조항</h1>

        <p class="term_info">
          1. 회사는 사용자 간 또는 사용자와 제3자 간에 발생한 분쟁에 대해 책임을 지지 않습니다.
        </p>

        <p class="term_info">
          2. 회사는 서비스 이용과 관련하여 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">9. 준거법 및 관할법원</h1>

        <p class="term_info">
          1. 본 약관은 대한민국 법령에 따라 해석 및 이행됩니다.
        </p>

        <p class="term_info">
          2. 서비스 이용과 관련하여 회사와 사용자 간에 발생한 분쟁에 대해서는 [회사 소재지]의 관할 법원을 전속 관할법원으로 합니다.
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">10. 회원 탈퇴 시 개인정보 외 삭제/보존되는 데이터</h1>
        <p class="term_info">
          회원 탈퇴 시 개인정보(닉네임, 로그인 아이디, 비밀번호)는 그 즉시 파기되며
          <br/>
          개인정보 외 서비스를 사용하면서 저장된 데이터의 처리는 다음과 같습니다.
        </p>

        <p class="term_info">
          · 삭제되는 데이터: 등록한 일일 퀴즈 및 일일 퀴즈 관련 이력 데이터, 퀴즈 답변/좋아요 관련 데이터
        </p>

        <p class="term_info">
          · 보존되는 데이터: 등록한 퀴즈(숨김 처리)
        </p>
      </div>

      <div class="term_sub_area">
        <h1 class="term_title">부칙</h1>

        <p class="term_info">
          본 약관은 2024년 7월 21일부터 적용됩니다.
        </p>
      </div>
    </div>
  </div>

  <Footer/>
</template>

<script>
import Footer from "@/components/layout/Footer";
export default {
  name: "Service",
  components: {Footer},
  methods: {
    privacy(){
      window.open("/term/privacy");
    },
  }
}
</script>

<style scoped>
@import "@/assets/main/term/Term.css";
</style>