<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div :class="'modal_quiz_container' + (answerShowFlag === 'Y' ? ' show_answer' : '')">
      <div class="quiz_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeQuizModal')">×</button>
      </div>
      <div class="quiz_area">
        <div v-if="hintKeywordList != null && hintKeywordList.length > 0">
          <button class="hint_btn" @click="showHint()"></button>
          <div class="hint_area">
            <template v-for="hint in hintKeywordList">
              <span class="hint_keyword">{{ hint }}</span>
            </template>
          </div>
        </div>
        <div class="quiz_title_area">
          <span id="quiz_title">{{ title }}</span>
        </div>
        <div class="quiz_problem_area">
          <span id="quiz_problem">{{ content }}</span>
        </div>
        <div class="quiz_input_correct_area">
          <template v-if="login">
            <textarea ref="answerTextArea" id="quiz_correct_keyword" name="quiz_correct_keyword" v-if="answerFlag === 'Y'" :value="answerInfo.answer" @input="handleResizeHeight"/>
            <textarea id="quiz_correct_keyword" name="quiz_correct_keyword" placeholder="자신의 생각을 답변해주세요." v-else/>
          </template>
          <template v-else>
            <textarea id="quiz_correct_keyword" name="quiz_correct_keyword" placeholder="로그인 후 작성 가능합니다." @click="loginRequiredMsg" readonly/>
          </template>

          <div class="daily_quiz_btn_area">
            <div class="check_box_area">
              <input type="checkbox" name="answer_show_flag" id="answer_show_flag" @click="answerShowFlag === 'N' ? answerShowFlag = 'Y' : answerShowFlag = 'N'"/>
              <label for="answer_show_flag">다른 답변 보기</label>
            </div>
            <div class="btn_area" v-if="answerFlag === 'N' && login">
              <button class="submit_btn" @click="submit()">제출</button>
            </div>

            <div class="btn_area" v-else-if="answerFlag === 'Y'">
              <button class="delete_btn" @click="deleteAnswer()"></button>
              <button class="like_btn" @click="like(quizId)" v-if="likeFlag === 'N'"></button>
              <button class="unlike_btn" @click="unlike(quizId)" v-if="likeFlag === 'Y'"></button>
              <button class="modify_btn" @click="modify()">수정</button>
            </div>
          </div>
        </div>

        <div class="answer_area" v-if="answerShowFlag === 'Y'">
          <div class="border_top_line"></div>
          <div class="answer_title_area">
            <p id="answer_area_title">다른 답변들</p>
          </div>
          <div class="answer_list_area">
            <ul id="answer_list">
              <template v-for="answerItem in answerList">
                <li class="answer_item">
                  <div class="reg_info">
                    <div class="profile_sm"><img :src="answerItem.profileImgUrl" @error="defaultImg"/></div>
                    <span class="answer_reg_name">{{answerItem.userName}}</span>
                    <span class="answer_reg_dt"> {{ $dateUtils.timeFormat(answerItem.regDt) }} </span>
                  </div>

                  <p class="answer_content">{{answerItem.answer}}</p>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyStr} from "@/utils/validationUtils";
import {toastFail, toastSuccess} from "@/utils/toastUtils";

export default {
  props: ['quizId'],
  name: 'QuizModal',
  data: () => ({
    answerFlag: 'N',
    answerInfo: {},
    title: '',
    content: '',
    hintKeywordList: [],
    answerList: [],
    likeFlag : 'N',
    answerShowFlag : 'N',
    login : false,
  }),
  computed: {
    isLoginCheck() {
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    },
  },
  watch: {
    isLoginCheck() {
      this.login = !!this.$store.state.auth.isLogin;
    },
  },
  created() {
    this.answerFlag= 'N';
    this.answerInfo= {};
    this.title= '';
    this.content= '';
    this.answerList= [];
    this.likeFlag = 'N';
    this.getProblemInfo(this.quizId);

    if(this.$store.state.auth.isLogin){
      this.alreadyAnswerCheck();
    }

    this.loadAnotherAnswerList();
  },
  methods: {
    alreadyAnswerCheck(){
      apiClient.get('/api/my-answer/' + this.quizId).then(data => {
        if(data.data.resData.answerFlag === 'Y'){
          this.answerFlag = data.data.resData.answerFlag;
          this.answerInfo.answer = data.data.resData.answer;
          this.answerInfo.regDt = data.data.resData.regDt;
          this.likeFlag = data.data.resData.likeFlag;
        }
      }).finally(e =>{
        this.handleResizeHeight();
      });
    },
    getProblemInfo(problemId) {
      apiClient.get('/api/quiz/info/' + problemId).then(data => {
        this.title = data.data.resData.title;
        this.content = data.data.resData.content;

        var hintKeywords = data.data.resData.hintKeywords;
        if(hintKeywords != ''){
          this.hintKeywordList = hintKeywords.split(",");
        }
      })
    },
    submit(){
      let answer = $('#quiz_correct_keyword').val();

      if(this.quizId == null || this.quizId === 0){
        toastFail("제출 오류 발생하였습니다.");
        return false;
      }

      if(isNullOrEmptyStr(answer)){
        toastFail("답변을 입력해주세요.");
        return false;
      }

      var params = {};
      params.problemId = this.quizId;
      params.answer = answer;

      apiClient.post('/api/quiz/answer/submit', params).then(data => {
        if(data.data.resData > 0){
          toastSuccess('답변 제출 성공하였습니다.');
          this.answerInfo.answer = $('#quiz_correct_keyword').val();
          this.answerFlag = 'Y';
          this.loadAnotherAnswerList();
        }else if(data.data.resData == -1){
          toastFail('이미 답변을 제출한 문제입니다.');
        }else{
          toastFail('답변 실패하였습니다.');
        }
      })
    },
    modify(){
      let answer = $('#quiz_correct_keyword').val();

      if(this.quizId == null || this.quizId === 0){
        toastFail("제출 오류 발생하였습니다.");
        return false;
      }

      if(isNullOrEmptyStr(answer)){
        toastFail("답변을 입력해주세요.");
        return false;
      }

      var params = {};
      params.problemId = this.quizId;
      params.answer = answer;

      apiClient.post('/api/quiz/answer/modify', params).then(data => {
        if(data.data.resData > 0){
          toastSuccess('답변 수정 하였습니다.');
          this.answerInfo.answer = $('#quiz_correct_keyword').val();
          this.loadAnotherAnswerList();
        }else{
          toastFail('답변 수정 실패하였습니다.');
        }
      }).catch(data => {
        toastFail('로그인 후 이용 가능합니다.');
      })
    },
    deleteAnswer(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("삭제 오류 발생하였습니다.");
        return false;
      }

      var params = {};
      params.problemId = this.quizId;

      apiClient.post('/api/quiz/answer/delete', params).then(data => {
        if(data.data.resData > 0){
          toastSuccess('답변 삭제하였습니다.');
          this.answerFlag = 'N';
        }else{
          toastFail('답변 삭제 실패하였습니다.');
        }
      }).catch(data => {
        toastFail('로그인 후 이용 가능합니다.');
      })
    },
    loadAnotherAnswerList(){
      apiClient.get('/api/answers/' + this.quizId).then(data => {
        this.answerList = data.data.resData;
      })
    },
    like(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("좋아요 오류 발생하였습니다.");
        return false;
      }

      apiClient.post('/api/quiz/' + this.quizId + '/like').then(data => {
        this.likeFlag = 'Y';
      })
    },
    unlike(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("좋아요 오류 발생하였습니다.");
        return false;
      }

      apiClient.post('/api/quiz/' + this.quizId + '/unlike').then(data => {
        this.likeFlag = 'N';
      })
    },
    showHint(){
      if($(".hint_area").hasClass('on')){
        $(".hint_area").removeClass('on');
        $(".hint_area").addClass('off');
      }else{
        $(".hint_area").removeClass('off');
        $(".hint_area").addClass('on');
      }
    },
    defaultImg(e){
      e.target.src = require('@/resource/img/person_l.png');
    },
    loginRequiredMsg(){
      toastFail('로그인 후 이용 가능합니다.');
    },
    handleResizeHeight(){
      this.$refs.answerTextArea.style.height = 'auto';
      this.$refs.answerTextArea.style.height = this.$refs.answerTextArea.scrollHeight + 'px';
    }
  },
}
</script>
<style scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/modal/quiz/QuizModal.css";
</style>