<template>
  <div class="term_area">
    <div class="term_content_area">
      <div class="term_sub_area">
        <h1 class="term_large_title">개인정보 처리방침</h1>
        <p class="term_info">
          WIZLQ는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」
          및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게
          관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게
          개인정보의 처리와 보호에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을
          신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
          수립・공개합니다.
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 1조 개인정보 처리목적</p>

        <p class="term_info">
          WIZLQ는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
          있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
          변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한
          조치를 이행할 예정입니다.
        </p>

        <p class="term_sub_title">1. 회원 가입 및 서비스 제공</p>

        <p class="term_info">
          회원 가입 의사 확인 및 회원 식별, 회원 서비스 제공 목적으로 개인정보를 처리합니다.
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 2조 처리하는 개인정보의 항목</p>

        <p class="term_info">
          WIZLQ는 「개인정보 보호법」에 따라 서비스 제공을 위해 필요
          최소한의 범위에서 다음과 같은 개인정보를 수집・이용합니다.
        </p>

        <p class="term_sub_title">1. 회원 서비스 운영</p>
        <p class="term_info">
          · 법적 근거 : 개인정보 보호법 제15조 제1항 제4호(‘계약 이행’)
        </p>
        <p class="term_info">
          · 필수 수집・이용 항목 : 닉네임, 로그인 아이디, 비밀번호
        </p>
        <p class="term_info">
          · 보유기간 : 회원 탈퇴 또는 관계 법령에 따른 기간까지
        </p>

        <p class="term_sub_title">2. 로그인 유지</p>
        <p class="term_info">
          · 수집・이용 항목 : 쿠키
        </p>
        <p class="term_info">
          · 보유기간 : 로그아웃 까지
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 3조 개인정보의 처리 및 보유기간</p>

        <p class="term_info">
          WIZLQ는 법령에 따른 개인정보 보유・이용기간 또는
          정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유・이용기간 내에서
          개인정보를 처리・보유합니다.
        </p>

        <p class="term_sub_title">
          각각의 개인정보 처리 및 보유 기간은 다음과 같습니다
        </p>

        <p class="term_info">
          1. 홈페이지 회원 가입 및 관리
        </p>
        <p class="term_info">
          회원 가입 일로부터 회원 탈퇴 시까지
          다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
        </p>
        <p class="term_info">
          1) 관계 법령 위반에 따른 수사・조사 등이 진행 중인 경우에는 해당 수사・조사
          종료 시까지
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 4조 개인정보의 파기 절차 및 방법</p>

        <p class="term_info">
          ① WIZLQ는 개인정보 보유기간의 경과, 처리목적 달성 등
          개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </p>
        <p class="term_info">
          ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
          경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
          달리하여 보존합니다.
          <br/>
          ※ 다른 법령에 따라 보존하는 개인정보의 항목과 보존 근거는 “5. 개인정보의
          처리 및 보유기간‘ 항목에서 확인 가능
        </p>

        <p class="term_info">
          ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        </p>

        <p class="term_sub_title">
          1. 파기절차
        </p>

        <p class="term_info">
          WIZLQ는 파기 사유가 발생한 개인정보를 선정하고,
          WIZLQ의 개인정보 보호책임자의 승인을 받아 개인정보를
          파기합니다.
        </p>


        <p class="term_sub_title">
          2. 파기방법
        </p>

        <p class="term_info">
          WIZLQ는 전자적 파일 형태로 기록・저장된 개인정보는 기록을
          재생할 수 없도록 파기하며, 종이 문서에 기록・저장된 개인정보는 분쇄기로
          분쇄하거나 소각하여 파기합니다
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 5조 개인정보의 안전성 확보조치</p>

        <p class="term_sub_title">
          ① WIZLQ는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
          취하고 있습니다.
        </p>

        <p class="term_info">
          1. 관리적 조치 : 내부관리계획 수립・시행, 전담조직 운영, 정기적 직원 교육
        </p>
        <p class="term_info">
          2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치,
          개인정보의 암호화, 보안프로그램 설치 및 갱신
        </p>
        <p class="term_info">
          3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 6조 개인정보 자동 수집 장치의 설치・운영 및 거부에 관한 사항</p>

        <p class="term_info">
          ① WIZLQ는 사용자에게 개별적인 서비스와 편의를 제공하기 위해
          이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
        </p>

        <p class="term_info">
          ② 쿠키는 웹사이트 운영에 이용되는 서버(http)가 정보주체의 브라우저에 보내는
          소량의 정보이며 정보주체의 PC 또는 모바일에 저장됩니다.
        </p>
        <p class="term_info">
          ③ 정보주체는 웹 브라우저 옵션 설정을 통해 쿠키 허용, 차단 등의 설정을 할 수
          있습니다. 다만, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할
          수 있습니다.
        </p>
        <p class="term_sub_title">
          &lt; 쿠키 허용 / 차단 방법 &gt;
        </p>

        <p class="term_sub_title">
          ▶ 웹 브라우저에서 쿠키 허용/차단
        </p>

        <p class="term_info">
          - 크롬(Chrome) : 웹 브라우저 설정 > 개인정보 보호 및 보안 > 인터넷 사용 기록
          삭제
        </p>
        <p class="term_info">
          - 엣지(Edge) : 웹 브라우저 설정 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터
          관리 및 삭제
        </p>

        <p class="term_sub_title">
          ▶ 모바일 브라우저에서 쿠키 허용/차단
        </p>

        <p class="term_info">
          - 크롬(Chrome) : 모바일 브라우저 설정 > 개인정보 보호 및 보안 > 인터넷 사용
          기록 삭제
        </p>
        <p class="term_info">
          - 사파리(Safari) : 모바일 기기 설정 > 사파리(Safari) > 고급 > 모든 쿠키 차단
        </p>
        <p class="term_info">
          - 삼성 인터넷 : 모바일 브라우저 설정 > 인터넷 사용 기록 > 인터넷 사용 기록 삭제
        </p>
      </div>
      <div class="term_sub_area">
        <p class="term_title">제 7조 정보주체의 권리・의무 및 행사방법</p>

        <p class="term_info">
          - 정보주체는 언제든지 ‘회원탈퇴’를 통해 개인정보의 수집 및 이용 동의 철회가
          가능합니다
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 8조 개인정보 보호책임자</p>

        <p class="term_info">
          ① WIZLQ는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
          같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>

        <p class="term_sub_title">
          ▶ 개인정보 보호책임자
        </p>

        <p class="term_info">
          성명 : 윤창환
          <br/>
          연락처 : wizlqservice@gmail.com
        </p>

        <p class="term_info">
          ② 정보주체는 WIZLQ의 서비스(또는 사업)을 이용하시면서 발생한
          모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
          보호책임자 및 담당부서로 문의할 수 있습니다. WIZLQ는
            정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">제 9조 권익침해 구제방법</p>

        <p class="term_info">
          ① 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
          한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할
          수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에
          문의하시기 바랍니다.
        </p>

        <p class="term_info">
          1. 개인정보 분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
          <br/>
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
          <br/>
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
          <br/>
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
        </p>

        <p class="term_info">
          ② WIZLQ는 정보주체의 개인정보자기결정권을 보장하고,
          개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이
          필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.
        </p>

        <p class="term_info">
          ② 정보주체는 WIZLQ의 서비스(또는 사업)을 이용하시면서 발생한
          모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
          보호책임자 및 담당부서로 문의할 수 있습니다. WIZLQ는
          정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
        </p>

        <p class="term_sub_title">
          ▶ 개인정보보호 관련 고객 상담 및 신고
        </p>

        <p class="term_info">
          연락처 : wizlqservice@gmail.com
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">개인정보 처리방침 시행일</p>
        <p class="term_info">이 개인정보 처리방침은 2024. 07. 21부터 적용됩니다</p>
      </div>
    </div>
  </div>

  <Footer/>
</template>

<script>
import Footer from "@/components/layout/Footer";
export default {
  name: "Privacy",
  components: {Footer}
}
</script>

<style scoped>
@import "@/assets/main/term/Term.css";
</style>