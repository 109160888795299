<template>
  <div class="board_area">
    <h1>체계적으로 공부 계획 세우는 법</h1>
    <img src="@/resource/img/board/board13.jpg" alt="공부하고 있는 남성"/>

    <p>체계적인 공부 계획을 세우는 것은 목표를 효율적으로 달성하기 위한 중요한 과정입니다. 이를 통해 시간을 효율적으로 사용하고, 집중력을 유지하며, 자신감을 얻을 수 있습니다. 체계적인 공부 계획을 세우기 위해 몇 가지 단계와 방법을 따라가면 도움이 됩니다.</p>

    <h2>1. 목표 설정하기</h2>
    <p>먼저, 구체적인 목표를 설정해야 합니다. 목표는 학습의 방향을 제시하고, 무엇을 공부해야 하는지 명확히 해줍니다. 목표를 설정할 때는 SMART 기준을 사용해보세요:</p>
    <ul style="margin-bottom: 10px">
      <li><strong>Specific (구체적)</strong>: 목표를 명확하게 정의하세요. 예를 들어, "영어를 공부하기"보다는 "3개월 내로 TOEIC 800점 달성하기"처럼 구체적인 목표를 설정합니다.</li>
      <li><strong>Measurable (측정 가능)</strong>: 목표를 달성했는지 확인할 수 있도록 수치화하세요. 점수, 완성한 책의 수, 특정 과목의 진도 등을 설정할 수 있습니다.</li>
      <li><strong>Achievable (달성 가능)</strong>: 현실적으로 달성 가능한 목표를 세우세요. 너무 높거나 낮은 목표는 동기 부여에 도움이 되지 않습니다.</li>
      <li><strong>Relevant (관련성)</strong>: 목표가 자신에게 얼마나 중요한지 생각해보세요. 자신의 미래 계획과 연관된 목표를 설정하세요.</li>
      <li><strong>Time-bound (시간 제한)</strong>: 목표에 기한을 설정하세요. "6개월 내로", "한 달 안에" 같은 구체적인 기간을 정하는 것이 중요합니다.</li>
    </ul>

    <h2>2. 일일, 주간, 월간 계획 수립하기</h2>
    <p>큰 목표를 설정한 후에는 이를 달성하기 위해 일일, 주간, 월간 계획을 세워야 합니다. 일일 계획은 가장 작은 단위로, 매일 해야 할 구체적인 작업을 포함해야 합니다. 주간 계획은 주요 과제를 완수하기 위한 단계로 구성하고, 월간 계획은 전체 목표를 달성하기 위한 큰 그림을 제시해야 합니다.</p>
    <p>각 계획은 구체적이고 현실적이어야 하며, 너무 과도하게 설정하지 않도록 주의해야 합니다. 예를 들어, "하루에 3시간씩 영어 듣기 연습"과 같은 현실적인 목표를 설정하세요.</p>

    <h2>3. 우선순위 정하기</h2>
    <p>모든 과제가 똑같이 중요한 것은 아닙니다. 중요한 과제에 우선순위를 두어야 합니다. 이를 위해 <strong>Eisenhower 매트릭스</strong>를 사용해 보세요:</p>
    <ul>
      <li><strong>긴급하고 중요한 일</strong>: 즉시 실행해야 할 과제</li>
      <li><strong>긴급하지 않지만 중요한 일</strong>: 계획을 세워 나중에 수행할 과제</li>
      <li><strong>긴급하지만 중요하지 않은 일</strong>: 위임하거나 빠르게 처리할 과제</li>
      <li><strong>긴급하지도 중요하지도 않은 일</strong>: 생략하거나 제한해야 할 과제</li>
    </ul>

    <h2>4. 학습 시간과 휴식 시간의 균형 잡기</h2>
    <p>공부 시간만큼 휴식 시간도 중요합니다. 효율적인 학습을 위해 <strong>Pomodoro 기법</strong>을 활용해 보세요. 25분간 집중해서 공부하고, 5분간 휴식하는 방식입니다. 이렇게 하면 집중력을 높이고 지치지 않게 공부할 수 있습니다. 긴 시간의 공부 후에는 좀 더 긴 휴식 시간도 필요합니다.</p>

    <h2>5. 진도 체크와 피드백 받기</h2>
    <p>계획을 세웠다면, 주기적으로 자신의 진도를 체크하고 피드백을 받는 것이 중요합니다. 주간 혹은 월간 리뷰 시간을 정해, 목표 달성 여부와 진행 상황을 점검해 보세요. 계획이 지나치게 빡빡하거나 느슨하지는 않은지 확인하고, 필요하다면 조정하세요.</p>

    <h2>6. 유연하게 계획 수정하기</h2>
    <p>계획은 상황에 따라 변경될 수 있습니다. 예상치 못한 일이 생기거나, 계획이 너무 어렵다고 느껴질 때는 유연하게 수정하세요. 중요한 것은 포기하지 않고, 꾸준히 계획을 따라가는 것입니다.</p>

    <p>이러한 방법을 통해 체계적이고 효율적인 공부 계획을 세울 수 있습니다. 꾸준히 계획을 점검하고 조정하며, 목표를 향해 나아가세요. 작은 성취가 쌓여 큰 목표를 이루게 될 것입니다.</p>
  </div>
</template>


<script>
export default{
  name: 'Board13'
}
</script>
<style>
@import "@/assets/main/board/Board.css";
</style>