import apiClient from "@/plugins/apiClient";

export default{
    namespace:true,
    state: () => ({
        dailyQuizId: null,
        dailyQuizTodayCheck: false
    }),
    mutations: {
        setDailyQuizId(state, dailyQuizId){
            state.dailyQuizId=dailyQuizId;
        },
        setDailyQuizTodayCheck(state, flag){
            state.dailyQuizTodayCheck=flag;

        },
    },
    actions:{
        setDailyQuizId({ commit }, dailyQuizId) {
            commit('setDailyQuizId', dailyQuizId);
        },
        setDailyQuizTodayCheck({ commit }, flag) {
            commit('setDailyQuizTodayCheck', flag);
        },
    },
}