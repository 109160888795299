<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="daily_quiz_reg_modal_container">
      <div class="daily_quiz_reg_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeDailyQuizRegModal')">×</button>
      </div>


      <div class="first_mode_select_step" v-if="mode === '' || step === 1">
        <p class="step_title">학습 모드를 선택하세요.</p>
        <p class="step_desc">본인의 학습 방식에 따라 모드를 선택해주세요!</p>
        <div class="mode_area">
          <div class="mode_select_area">
            <button type="button" :class="'mode_select' + (mode === 'select' ? ' selected' : '')" @click="selectMode('select')"/>
            <p class="mode_desc">선택 모드</p>
          </div>
          <div class="mode_random_area">
            <button type="button" :class="'mode_random' + (mode === 'random' ? ' selected' : '')" @click="selectMode('random')"/>
            <p class="mode_desc">랜덤 모드</p>
          </div>
        </div>

        <div class="next_btn_area">
          <button type="button" class="disable_btn" v-if="mode === ''">다음</button>
          <button type="button" class="next_btn" v-else @click="cntInputStep">다음</button>
        </div>
      </div>
      <div class="second_mode_random_step" v-if="step === 2">
        <p class="step_title">일일 퀴즈 횟수와 목표 수행 수를 입력해주세요.</p>
        <div class="cnt_input_area">
          <div class="daily_cnt_area">
            <p class="step_desc">일일 퀴즈 횟수</p>
            <div class="circle_chart">
              <RadialProgress
                  :diameter="130"
                  :completed-steps="dailyCnt"
                  :total-steps="5"
                  :startColor="'#df8d8d'"
                  :stopColor="'#df8d8d'"
                  :animateSpeed="400"
                  :innerStrokeColor="'#D0F0E5'"
                  :innerStrokeWidth="12"
                  :strokeWidth="12"
                  :fps="100">
                <div class="circle_chart_info">
                  <span class="daily_cnt_label">5 / </span>
                  <input type="number" name="daily_cnt" id="daily_cnt" @focusin="focusSelf($event)" @input="handleDailyCntChange($event)" :value="dailyCnt"/>
                </div>
              </RadialProgress>
            </div>
          </div>
          <div class="target_cnt_area">
            <p class="step_desc">목표 수행 수</p>
            <div class="circle_chart">
              <RadialProgress
                  :diameter="130"
                  :completed-steps="targetCnt"
                  :total-steps="30"
                  :startColor="'#EB85FF'"
                  :stopColor="'#EB85FF'"
                  :animateSpeed="400"
                  :innerStrokeColor="'#D0F0E5'"
                  :innerStrokeWidth="12"
                  :strokeWidth="12"
                  :fps="100">
                <div class="circle_chart_info">
                  <span class="target_cnt_label">30 / </span>
                  <input type="number" name="target_cnt" id="target_cnt" @focusin="focusSelf($event)" @input="handleTargetCntChange($event)" :value="targetCnt"/>
                </div>
              </RadialProgress>
            </div>
          </div>
        </div>

        <div class="next_btn_area">
          <button type="button" class="next_btn" v-if="dailyCnt != 0 && targetCnt != 0" @click="nextStep">다음</button>
          <button type="button" class="disable_btn" v-else>다음</button>
        </div>
      </div>

      <div class="third_mode_select_step" v-if="mode === 'select' && step === 3">
        <p class="step_title">내가 좋아요 한 퀴즈 중 <br/>일일 퀴즈에 등록할 퀴즈를 선택해 주세요.</p>

        <ul class="quiz_item_list">
          <template v-for="(myQuizItem, index) in myQuizList" >
            <div :class="this.selectQuizList.includes(myQuizItem.quizId)
                            ? 'quiz_item' + ' id' + myQuizItem.quizId + ' add'
                            : 'quiz_item' + ' id' + myQuizItem.quizId"
                         @click="addDailyQuizList(myQuizItem.quizId, $event)">
              <p class="category">{{ myQuizItem.categoryName }}</p>
              <span class="title">{{ myQuizItem.title }}</span>

              <div class="add_icon_area">
                <div class="add_icon"></div>
              </div>
            </div>
          </template>
        </ul>

        <div class="next_btn_area">
          <button type="button" class="disable_btn" v-if="selectQuizList.length != dailyCnt">{{selectQuizList.length}} / {{dailyCnt}}</button>
          <button type="button" class="next_btn" v-else @click="selectQuizComplete">다음</button>
        </div>
      </div>

      <div class="third_mode_random_step" v-if="(mode === 'random' && step === 3) || (mode === 'select' && step === 4)">
        <p class="step_title">일일 퀴즈 이름을 입력해 주세요.</p>
        <input type="text" name="daily_quiz_name" id="daily_quiz_name" @input="handleDailyQuizName($event)"/>

        <div class="next_btn_area">
          <button type="button" class="disable_btn" v-if="dailyQuizName === ''">다음</button>
          <button type="button" class="next_btn" v-else @click="mode === 'random' ? saveRandomDailyQuiz() : saveSelectDailyQuiz()">다음</button>
        </div>
      </div>

      <div class="complete_step" v-if="(mode === 'random' && step === 4) || (mode === 'select' && step === 5)">
        <p class="step_title">성공적으로 등록되었습니다.</p>
        <p class="step_desc">등록된 일일 퀴즈는<br/>매일 학습 관리에서 확인할 수 있습니다.</p>
        <div class="complete_img_area">

          <div class="complete_img"></div>
        </div>

        <div class="confirm_btn_area">
          <button type="button" class="confirm_btn" @click="completeReload">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RadialProgress from "vue3-radial-progress";
import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyList, isNullOrEmptyStr, isNullOrZero} from "@/utils/validationUtils";
import {toastFail} from "@/utils/toastUtils";

export default {
  name: 'DailyQuizRegModal',
  components: {RadialProgress},
  data:() => ({
    categoryArr: [],
    mode: '',
    step: 1,
    dailyCnt: 0,
    targetCnt: 0,
    myQuizList : [],
    selectQuizList: [],
    selectCount: 0,
    dailyQuizName: ''
  }),
  created() {
  },
  methods: {
    selectMode(mode){
      this.mode = mode;
    },
    cntInputStep(){
      this.step += 1;
    },
    handleDailyCntChange(event){
      let val = event.target.value;

      if(isNaN(val)){
        this.dailyCnt = 0
      }

      if(val > 5){
        this.dailyCnt = 5;
      }else if(val < 0){
        this.dailyCnt = 0;
      }else{
        this.dailyCnt = event.target.value;
      }
    },
    handleTargetCntChange(event){
      let val = event.target.value;

      if(isNaN(val)){
        this.targetCnt = 0
      }

      if(val > 30){
        this.targetCnt = 30;
      }else if(val < 0){
        this.targetCnt = 0;
      }else{
        this.targetCnt = event.target.value;
      }
    },
    focusSelf(event){
      $('#' + event.target.id).select();
    },
    nextStep(){
      if(this.mode === 'select'){
        apiClient.get('/api/liked-quizzes').then(data => {
          this.myQuizList = data.data.resData.likedQuizItemList;

          this.step += 1;
        });

      }else{
        this.step += 1;
      }
    },
    addDailyQuizList(quizId, event){
      if($(event.target).hasClass('add')){
        $(event.target).removeClass('add');

        this.selectQuizList = this.selectQuizList.filter((ele) => {
          return ele != quizId;
        });

        this.selectCount-=1;

        return false;
      }

      if(this.selectQuizList.includes(quizId)){
        return false;
      }
      if(this.selectQuizList.length >= this.dailyCnt){
        toastFail("최대 " + this.dailyCnt + "개까지만 등록 가능합니다.");
        return false;
      }

      this.selectQuizList.push(quizId);
      this.selectCount+=1;
      $(event.target).addClass('add');
    },
    selectQuizComplete(){
      this.step += 1;
    },
    handleDailyQuizName(event){
      this.dailyQuizName = event.target.value;
    },
    saveSelectDailyQuiz(){
      if(isNullOrZero(this.dailyCnt)){
        toastFail("일일 퀴즈 횟수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrZero(this.targetCnt)){
        toastFail("목표 수행 수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      console.log(this.quizList);

      if(isNullOrEmptyList(this.selectQuizList)){
        toastFail("선택된 일일 퀴즈 리스트가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrEmptyStr(this.dailyQuizName)){
        toastFail("일일 퀴즈 이름을 입력해주세요.");
        return false;
      }

      let params = {};
      params.mode = 'S';
      params.dailyCnt = this.dailyCnt;
      params.targetCnt = this.targetCnt;
      params.dailyQuizName = this.dailyQuizName;
      params.quizList = this.selectQuizList;

      apiClient.post('/api/daily-quiz', params).then(data => {
        this.step += 1;
      });
    },
    saveRandomDailyQuiz(){
      if(isNullOrZero(this.dailyCnt)){
        toastFail("일일 퀴즈 횟수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrZero(this.targetCnt)){
        toastFail("목표 수행 수가 존재하지 않습니다. 다시 등록해주세요.");
        return false;
      }

      if(isNullOrEmptyStr(this.dailyQuizName)){
        toastFail("일일 퀴즈 이름을 입력해주세요.");
        return false;
      }

      let params = {};
      params.mode = 'R';
      params.dailyCnt = this.dailyCnt;
      params.targetCnt = this.targetCnt;
      params.dailyQuizName = this.dailyQuizName;
      params.quizList = [];

      apiClient.post('/api/daily-quiz', params).then(data => {
        this.step += 1;
        this.emitter.emit("getMyDailyQuizList");
      });
    },
    completeReload(){
      location.reload();
    }
  }
}
</script>

<style scoped>
  @import "@/assets/modal/manage/DailyQuizRegModal.css";
</style>