export function isNullOrEmptyStr(val){
    return val == null || val === '';
}

export function isNullOrZero(val){
    return val == null || val === 0;
}

export function isNullOrEmptyList(list){
    return list == null || list.length === 0;
}