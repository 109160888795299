<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="login_modal_container">
      <div class="login_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeLoginModal')">×</button>
      </div>

      <div class="logo_area"><div class="logo_img"></div></div>

      <div class="input_area">
      <input type="text" id="login_id" name="login_id" placeholder="아이디" @keyup.enter="memberLogin"/>

      <input type="password" id="login_pw" name="login_pw" placeholder="패스워드" @keyup.enter="memberLogin"/>
      <p class="valid msg"></p>

      </div>
      <div class="btn_area">
        <button class="login_btn" @click="memberLogin">로그인</button>
        <button class="register_btn" @click="openRegisterModal">회원가입</button>
      </div>
    </div>
  </div>
</template>

<script>


import {mapActions} from "vuex";
import {isNullOrEmptyStr} from '@/utils/validationUtils';

export default {
  name: 'LoginModal',
  data:() => ({
    loginIdValid: false,
    passwordValid: false,
  }),
  created() {
  },
  methods: {
    async memberLogin() {
      await this.valid();

      if(this.loginIdValid && this.passwordValid) {
        let param = {};
        param.loginId = $('#login_id').val();
        param.password = $('#login_pw').val();

        await this.loginAction(param)
      }
    }, ...mapActions(['loginAction']),
    openRegisterModal(){
      this.$emit('closeLoginModal');
      this.$emit('openRegisterModal');
    },
    async valid(){
      let loginId = $("#login_id").val();
      let password = $("#login_pw").val();

      this.loginIdValid = false;
      if (isNullOrEmptyStr(loginId)) {
        $(".valid.msg").text('아이디를 입력해주세요.');
        return;
      } else {
        $(".valid.msg").text('');
        this.loginIdValid = true;
      }

      this.passwordValid = false;
      if (isNullOrEmptyStr(password)) {
        $(".valid.msg").text('비밀번호를 입력해주세요.');
        return;
      } else {
        $(".valid.msg").text('');
        this.passwordValid = true;
      }
    }
  },
}
</script>

<style scoped>
@import "@/assets/modal/login/LoginModal.css";
</style>