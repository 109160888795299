import { createApp } from 'vue'
import Main from "@/components/layout/Main";
import store from './store'
import mitt from 'mitt'
import datePlugin from "@/plugins/datePlugin";
import router from './utils/router';
import apiClient from "@/plugins/apiClient";
import vueCookies from "vue-cookies";
import sal from 'sal.js';
import "sal.js/dist/sal.css";
import VueGtag from "vue-gtag";

const emitter = mitt();
const app = createApp(Main);

apiClient.defaults.withCredentials = true;
app.config.globalProperties.emitter = emitter;

app.use(store)
    .use(sal)
    .use(datePlugin)
    .use(router)
    .use(vueCookies)
    .use(VueGtag, {
        config: {
            id: 'G-HQ16L4MHC2'
        }
    }, router)
    .use(Vue3Toastify, {
        autoClose: 3000,
    })
    .mount('#app');
