<template>
  <div class="board_area">
    <h1>귀찮음을 이겨내고 루틴대로 공부하는 법</h1>
    <img src="@/resource/img/board/board11.jpg" alt="노트들"/>
    <p>공부를 하려는 의지와는 달리, 막상 공부를 시작하려고 하면 귀찮음이 밀려오는 경우가 많습니다. 이러한 귀찮음은 모든 사람이 느끼는 자연스러운 감정이지만, 그에 굴복하지 않고 꾸준히 공부할 수 있는 방법이 있습니다. 이제 귀찮음을 이겨내고 루틴대로 공부하는 몇 가지 방법을 알아보겠습니다.</p>

    <h2>1. 작은 목표 설정하기</h2>
    <p>귀찮음을 극복하기 위해 가장 중요한 것은 작은 목표를 설정하는 것입니다. 큰 목표를 한 번에 이루려고 하면 지쳐버리기 쉽습니다. 대신 하루에 해야 할 공부량을 적당한 수준으로 나누어 소화 가능한 단위로 쪼개보세요. 예를 들어, "하루에 30페이지 읽기" 대신 "10페이지씩 3번에 나누어 읽기"로 목표를 수정하면 부담이 줄어듭니다. 작은 목표를 달성할 때마다 성취감을 느낄 수 있어 꾸준히 공부할 동기를 부여받게 됩니다.</p>

    <h2>2. 규칙적인 시간 확보하기</h2>
    <p>일정한 시간에 공부를 시작하는 것은 루틴을 형성하는 데 큰 도움이 됩니다. 매일 같은 시간에 공부를 시작하면, 점차 그 시간이 되면 자연스럽게 공부 모드로 전환될 수 있습니다. 예를 들어, 매일 저녁 7시에 1시간씩 공부하는 습관을 들이면, 몸과 마음이 그 시간에 맞춰 준비될 것입니다. 이렇게 규칙적으로 공부하는 시간을 확보하면, 귀찮음도 점차 줄어들고, 공부가 일상의 일부로 자연스럽게 자리잡게 됩니다.</p>

    <h2>3. 환경 정리와 분산 요소 제거하기</h2>
    <p>공부를 방해하는 요소들이 주변에 있다면 쉽게 집중력을 잃고 귀찮음을 느끼게 됩니다. 따라서 공부를 시작하기 전, 주변 환경을 정리하고 불필요한 물건이나 디지털 기기를 멀리하는 것이 좋습니다. 예를 들어, 스마트폰을 다른 방에 두거나, 소셜 미디어 알림을 차단하는 등 환경을 정돈하여 집중력을 높이는 것이 중요합니다. 또한, 책상 위에는 필요한 교재와 필기 도구만 놓아두어 깔끔하게 정리하는 습관을 들이세요. 환경이 정돈되면 공부에 대한 귀찮음이 줄어들고, 더 쉽게 루틴을 유지할 수 있습니다.</p>

    <h2>4. 보상 시스템 활용하기</h2>
    <p>보상 시스템은 귀찮음을 극복하고 루틴을 유지하는 데 효과적입니다. 자신이 정한 목표를 달성할 때마다 작은 보상을 주는 것이 좋은 방법입니다. 예를 들어, "30분 동안 집중해서 공부를 마치면 좋아하는 간식을 먹는다"는 식의 작은 보상을 설정해보세요. 이런 보상은 뇌에 긍정적인 자극을 주어 공부에 대한 귀찮음을 줄이고, 꾸준히 공부할 수 있는 동기부여가 됩니다.</p>

    <h2>5. 자신을 격려하고 인정하기</h2>
    <p>공부를 하면서 귀찮음을 이겨내는 과정에서 스스로를 격려하고 인정하는 태도가 중요합니다. 때로는 귀찮음을 느끼고 루틴을 따르지 못할 때도 있습니다. 하지만 그럴 때마다 자신을 탓하기보다는 "오늘은 조금 쉬어가도 괜찮아, 내일 다시 열심히 해보자"라고 긍정적으로 생각하는 것이 필요합니다. 이렇게 자신을 격려하며, 작은 성취도 스스로 인정하는 자세를 갖춘다면, 귀찮음을 극복하고 공부를 지속하는 데 큰 도움이 됩니다.</p>

    <h2>6. 함께 공부하는 사람 찾기</h2>
    <p>혼자서 공부하는 것이 지루하고 귀찮을 때는 함께 공부할 사람을 찾는 것도 좋은 방법입니다. 스터디 그룹을 구성하거나 친구와 함께 공부하면 서로의 진도를 체크하며 동기부여가 될 수 있습니다. 다른 사람과 함께하는 공부는 외부적인 압박감이 아닌, 자연스러운 경쟁과 협력을 통해 자신의 루틴을 더욱 잘 지키도록 돕습니다.</p>

    <h2>결론</h2>
    <p>귀찮음을 이겨내고 루틴대로 공부하는 것은 결코 쉬운 일이 아니지만, 작은 습관을 통해 천천히 극복할 수 있습니다. 위의 방법들을 시도하며 자신만의 루틴을 만들어가면서 꾸준히 노력해보세요. 결국, 반복적인 행동이 습관이 되고, 습관이 목표를 이루는 큰 힘이 될 것입니다.</p>
  </div>
</template>


<script>
export default{
  name: 'Board11'
}
</script>
<style>
@import "@/assets/main/board/Board.css";
</style>