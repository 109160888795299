import { createStore } from 'vuex'
import auth from './auth'
import dailyQuiz from './dailyQuiz'

export default createStore({
  modules: {
    auth,
    dailyQuiz
  }
})
