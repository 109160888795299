
import Home from "@/components/main/home/Home";
import {createMemoryHistory, createRouter, createWebHistory} from "vue-router";
import MyQuizManage from "@/components/main/manage/MyQuizManage";
import DailyQuizManage from "@/components/main/manage/DailyQuizManage";
import Content from "@/components/main/content/Content";
import store from "@/store";
import Privacy from "@/components/main/term/Privacy";
import Service from "@/components/main/term/Service";
import PrivacyAgree from "@/components/main/term/PrivacyAgree";
import {toastFail} from "@/utils/toastUtils";
import Board1 from "@/components/board/Board1";
import Board2 from "@/components/board/Board2";
import Board3 from "@/components/board/Board3";
import Board4 from "@/components/board/Board4";
import Board5 from "@/components/board/Board5";
import Board6 from "@/components/board/Board6";
import Board7 from "@/components/board/Board7";
import Board8 from "@/components/board/Board8";
import Board9 from "@/components/board/Board9";
import Board10 from "@/components/board/Board10";
import Board11 from "@/components/board/Board11";
import Board12 from "@/components/board/Board12";
import Board13 from "@/components/board/Board13";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', alias: '/home', component: Home},
        { path: '/quiz/:id', alias: '/quiz/:id', component: Content},
        { path: '/term/privacy', alias: '/term/privacy', component: Privacy},
        { path: '/term/service', alias: '/term/service', component: Service},
        { path: '/term/privacy-agree', alias: '/term/privacy-agree', component: PrivacyAgree},
        { path: '/manage', alias: '/manage', component: MyQuizManage, meta: { requiresAuth: true}},
        { path: '/daily', alias: '/daily', component: DailyQuizManage, meta: { requiresAuth: true}},
        { path: '/board/1', alias: '/board/1', component: Board1},
        { path: '/board/2', alias: '/board/2', component: Board2},
        { path: '/board/3', alias: '/board/3', component: Board3},
        { path: '/board/4', alias: '/board/4', component: Board4},
        { path: '/board/5', alias: '/board/5', component: Board5},
        { path: '/board/6', alias: '/board/6', component: Board6},
        { path: '/board/7', alias: '/board/7', component: Board7},
        { path: '/board/8', alias: '/board/8', component: Board8},
        { path: '/board/9', alias: '/board/9', component: Board9},
        { path: '/board/10', alias: '/board/10', component: Board10},
        { path: '/board/11', alias: '/board/11', component: Board11},
        { path: '/board/12', alias: '/board/12', component: Board12},
        { path: '/board/13', alias: '/board/13', component: Board13},
    ],
})

router.beforeEach(async (to, from, next) => {
    const isAccessTokenValid = await store.dispatch('accessTokenValid');

    if (!isAccessTokenValid) {
        const isRefreshTokenValid = await store.dispatch('refreshTokenValid');
        if (!isRefreshTokenValid){
            await store.dispatch('logout');
        }
    }

    if (to.meta.requiresAuth) {
        console.log("asd");
        if(!store.getters.isAuthenticated) {
            toastFail("로그인 후 이용 가능합니다.");
            next('/');
        }else{
            $('.main_content_area').animate( { scrollTop : $('.main_content_area').offset().top }, 200 );
            next();
        }
    } else {
        $('.main_content_area').animate( { scrollTop : $('.main_content_area').offset().top }, 200 );
        next();
    }
})

export default router;