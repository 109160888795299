<template>
  <div class="term_area" style="height: 920px;">
    <div class="term_content_area">
      <div class="term_sub_area">
        <h1 class="term_large_title">개인정보 수집 및 이용 동의서</h1>
        <p class="term_info">
          WIZLQ는 회원님의 개인정보를 매우 중요하게 생각하며,
          본 동의서는 회원님의 개인정보 수집 및 이용에 대한 동의를 얻기 위한 동의서 입니다.
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">1. 개인정보 수집 및 이용 항목</p>

        <p class="term_info">
          · 필수 항목: 닉네임, 로그인 아이디, 비밀번호
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">2. 개인정보 수집 및 이용 목적</p>

        <p class="term_info">
          · 회원 가입 의사 확인
        </p>

        <p class="term_info">
          · 회원 식별
        </p>

        <p class="term_info">
          · 회원 서비스 제공
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">3. 개인정보 보유기간</p>

        <p class="term_info">
          회원 가입 일로부터 회원 탈퇴 시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
        </p>

        <p class="term_info">
          1) 관계 법령 위반에 따른 수사・조사 등이 진행 중인 경우에는 해당 수사・조사 종료 시까지
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_title">4. 개인정보 수집 및 이용 동의를 거부할 권리</p>

        <p class="term_info">
          개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다.
          개인정보 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려우며 서비스 이용이 제한될 수 있습니다.
        </p>
      </div>

      <div class="term_sub_area">
        <p class="term_sub_title">개인정보 관련 자세한 내용은 <span class="term_link" @click="privacy">[개인정보 처리방침]</span>을 확인해주세요.</p>
      </div>
    </div>
  </div>

  <Footer/>
</template>

<script>
import Footer from "@/components/layout/Footer";
export default {
  name: "PrivacyAgree",
  components: {Footer},
  methods: {
    privacy(){
      window.open("/term/privacy");
    },
  }
}
</script>

<style scoped>
@import "@/assets/main/term/Term.css";
</style>