<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class="modal_register_container" v-if="!registComplete">
      <div class="register_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeRegisterModal')">×</button>
      </div>
      <div class="logo_area"><div class="logo_img"></div></div>
      <div class="input_area">
        <label id="input_label_register_name" for="register_name">
          닉네임
        </label>
        <input type="text" id="register_name" name="register_name" placeholder="이름을 입력해 주세요." @keyup.enter="regist"/>
        <p class="valid name_txt"></p>

        <label id="input_label_register_id" for="register_id">
          아이디
        </label>
        <input type="text" id="register_id" name="register_id" placeholder="아이디를 입력해 주세요." @focusout="loginIdExistCheck" @keyup.enter="regist"/>
        <p class="valid login_id_txt"></p>

        <label id="input_label_register_pw" for="register_pw">
          비밀번호
        </label>
        <input type="password" id="register_pw" name="register_pw" placeholder="비밀번호를 입력해 주세요." @keyup.enter="regist"/>
        <input type="password" id="register_pw_re" name="register_pw_re" placeholder="비밀번호를 다시 입력해 주세요." @keyup.enter="regist"/>
        <p class="valid password_txt"></p>
      </div>
      <div class="term_agree_area">
        <label id="term_label">
          약관동의
        </label>
        <div class="agree_all_area">
          <input type="checkbox" id="agree_all" name="agree_all" @click="agreeAll"/>
          <label id="agree_all_label" for="agree_all">
            전체동의
          </label>
        </div>
        <div class="service_agree_area">
          <div>
            <input type="checkbox" id="service_agree" name="service_agree" @click="agree"/>
            <label id="service_agree_label" for="service_agree">
              (필수) 이용약관 동의
            </label>
          </div>
          <span class="term_detail" @click="service">보기</span>
        </div>
        <div class="privacy_agree_area">
          <div>
            <input type="checkbox" id="privacy_agree" name="privacy_agree" @click="agree"/>
            <label id="privacy_agree_label" for="privacy_agree">
              (필수) 개인정보 수집 및 이용 동의
            </label>
          </div>
          <span class="term_detail" @click="privacyAgree">보기</span>
        </div>
        <p class="valid term_agree_txt"></p>
      </div>
      <div class="btn_area">
        <button class="submit_btn" @click="regist">가입하기</button>
      </div>
    </div>
    <div class="modal_register_container complete" v-else>
      <div class="register_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeRegisterModal')">×</button>
      </div>

      <div class="logo_area"><div class="logo_img"></div></div>
      <p class="step_title">회원가입을 축하합니다!</p>
      <p class="step_desc">로그인 후 WILZQ의 서비스를 이용할 수 있습니다.</p>

      <div class="complete_img_area">
        <div class="complete_img"></div>
      </div>

      <div class="btn_area">
        <button type="button" class="login_btn" @click="openLoginModal">로그인</button>
      </div>
    </div>
  </div>
</template>

<script>

import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyStr} from '@/utils/validationUtils';

export default {
  data: () => ({
    loginIdExist: '',
    nameValid: false,
    loginIdValid: false,
    passwordValid: false,
    passwordReValid: false,
    termAgreeValid: false,
    registComplete: false
  }),
  props: ['registerModalOn'],
  name: 'RegisterModal',
  methods:{
    async loginIdExistCheck() {
      let cnt = 0;
      let param = {};
      param.loginId = $('#register_id').val();

      if ($('#register_id').val() !== '') {
         cnt = await apiClient.post('/api/login-id/valid', param).then(data => {
          if (data.data.resData > 0) {
            return 1;
          } else {
            return 0;
          }
        });
      }

      return cnt;
    },
    async regist() {
      await this.valid();

      if (this.nameValid && this.loginIdValid && this.passwordValid && this.passwordReValid && this.termAgreeValid) {
        let param = {};
        param.name = $('#register_name').val();
        param.loginId = $('#register_id').val();
        param.password = $('#register_pw').val();
        param.termAgree = this.termAgreeValid ? 'Y' : 'N';

        apiClient.post('/auth/signup', param).then(data => {
          if (data.data.resCode == '00') {
            this.registComplete = true;
          }
        });
      }
    },
    async valid(){
      let name = $("#register_name").val();
      let loginId = $("#register_id").val();
      let password = $("#register_pw").val();
      let passwordRe = $("#register_pw_re").val();

      this.nameValid = false;
      if (isNullOrEmptyStr(name)) {
        $(".valid.name_txt").text('이름을 입력해주세요.');
      } else {
        $(".valid.name_txt").text('');
        this.nameValid = true;
      }

      this.loginIdValid = false;
      if (isNullOrEmptyStr(loginId)) {
        $(".valid.login_id_txt").text('아이디를 입력해 주세요.');
      } else if (loginId.length < 5 || loginId.length > 20) {
        $(".valid.login_id_txt").text('아이디의 길이는 5 ~ 20자이어야 합니다.');
      } else if (!this.regexValid(/^[a-zA-Z0-9\S]+$/, loginId)) {
        $(".valid.login_id_txt").text('아이디는 영문 대소문자, 숫자, 특수 문자만 사용할 수 있습니다.');
      } else if (!this.regexValid(/^[a-zA-Z0-9][a-zA-Z0-9\S]*[a-zA-Z0-9]$/, loginId)) {
        $(".valid.login_id_txt").text('아이디에 처음과 끝에 특수 문자를 사용할 수 없습니다.');
      } else if (!this.regexValid(/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]+$/, loginId)) {
        $(".valid.login_id_txt").text('아이디에 허용되지 않는 문자가 포함되어 있습니다.');
      } else if (await this.loginIdExistCheck() > 0) {
        $(".valid.login_id_txt").text('이미 사용 중인 아이디입니다.');
      } else{
        $(".valid.login_id_txt").text('');
        this.loginIdValid = true;
      }

      this.passwordValid = false;
      if (isNullOrEmptyStr(password)) {
        $(".valid.password_txt").text('비밀번호를 입력해 주세요.');
      } else if (password.length < 8 || password.length > 20) {
        $(".valid.password_txt").text('비밀번호의 길이는 8 ~ 20자이어야 합니다.');
      } else if (!this.regexValid(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])/, password)) {
        $(".valid.password_txt").text('비밀번호에는 최소 하나의 대문자, 소문자, 숫자, 특수 문자가 포함되어야 합니다.');
      } else if (!this.regexValid(/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]+$/, password)) {
        $(".valid.password_txt").text('비밀번호에 허용되지 않는 문자가 포함되어 있습니다.');
      } else {
        $(".valid.password_txt").text('');
        this.passwordValid = true;
      }

      if (this.passwordValid) {
        if (isNullOrEmptyStr(passwordRe)) {
          $(".valid.password_txt").text('비밀번호 확인을 입력해 주세요.');
        } else if (password !== passwordRe) {
          $(".valid.password_txt").text('비밀번호와 비밀번호 확인이 일치하지 않습니다.');
        } else {
          $(".valid.password_txt").text('');
          this.passwordReValid = true;
        }
      }

      this.termAgreeValid = false;
      if(!$('#service_agree').is(':checked') || !$('#privacy_agree').is(':checked')){
        $(".valid.term_agree_txt").text('약관을 모두 동의해주세요.');
      } else {
        $(".valid.term_agree_txt").text('');
        this.termAgreeValid = true;
      }
    },
    regexValid(regex, str){
      return regex.test(str);
    },
    openLoginModal(){
      this.$emit('openLoginModal');
      this.$emit('closeRegisterModal');
    },
    service(){
      window.open("/term/service");
    },
    privacyAgree(){
      window.open("/term/privacy-agree");
    },
    agree(){
      if(!$('#service_agree').is(':checked') || !$('#privacy_agree').is(':checked')){
        $('#agree_all').prop('checked', false);
      }else{
        $('#agree_all').prop('checked', true);
      }
    },
    agreeAll(){
      if(!$('#service_agree').is(':checked') || !$('#privacy_agree').is(':checked')){
        $('#service_agree').prop('checked', true);
        $('#privacy_agree').prop('checked', true);
      }else{
        $('#service_agree').prop('checked', false);
        $('#privacy_agree').prop('checked', false);
      }
    }
  }
}
</script>
<style scoped>
  @import "@/assets/modal/regist/RegisterModal.css";
</style>