import axios from "axios";
import store from "@/store";
import router from "@/utils/router";
import {toastFail} from "@/utils/toastUtils";


const apiClient = axios.create({
    /*timeout: 1000,*/
});

// 응답 인터셉터 설정
apiClient.interceptors.response.use(function (res) {
    if(res.data.resCode === '9999'){
        toastFail("로그인 후 이용해주세요.");
        store.dispatch('logout');
        router.push('/');

        $('.loader_div').hide();

        return Promise.reject("auth verify failed");
    }

    if(res.data.resCode === '999'){
        toastFail(res.data.resMessage);

        return Promise.reject("validation exception");
    }

    return res;
}, function (error) {
    toastFail("알 수 없는 오류가 발생하였습니다.");

    $('.loader_div').hide();
    return Promise.reject(error);
})

export default apiClient;