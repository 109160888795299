<template>
  <div>
    <div v-if="dailyQuizExist === true">
    <Calendar ref="calendar"
              :attributes="attributes"
              @dayclick="daySelect"
              @did-move="calendarUpdatePageEvent"/>
    <LoaderItem/>
    </div>
    <div v-else-if="dailyQuizExist === false">
      <div class="nothing" >
        <p class="nothing_txt">선택된 일일 퀴즈가 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/common/Loader";
import apiClient from "@/plugins/apiClient";

import {Calendar} from 'v-calendar';
import 'v-calendar/style.css';
import LoaderItem from "@/components/common/LoaderItem";

export default {
  name: 'DailyQuizCalendar',
  components: {LoaderItem, Loader,Calendar},
  props: ['dailyQuizExist'],
  data: () => ({
    attributes:[
        {
          key: 'selectDate',
          highlight: {
            color: 'purple',
            fillMode: 'solid',
            contentClass: 'italic',
          },
          dates: new Date(),
        }
      ],
    currentMonthAnswerDateList: [],
    calendar: {},
    mode: '',
  }),
  mounted(){
    this.calendar = this.$refs.calendar;

    this.emitter.on("calendarResetToday",() => {
      this.calendarResetToday();
    });

    this.emitter.on("getMonthAnswerHistoryDateList",(emitMap) => {
      this.getMonthAnswerHistoryDateList(emitMap.dailyQuizId, emitMap.searchDate);
    });

    this.emitter.on("moveCalendar",(date) => {
      if(this.$refs.calendar != null){
        this.$refs.calendar.move(date);
      }
    });

    this.emitter.on("getDailyQuizAnswerHistoryList",(date) => {
      this.getDailyQuizAnswerHistoryList(date);
    });

    this.emitter.on("getRandomQuizHistory",(param) => {
      this.getRandomQuizHistory(param);
    });
  },
  methods: {
    calendarResetToday(){
      let selectedDateMap = {
        key: 'selectDate',
        highlight: {
          color: 'purple',
          fillMode: 'solid',
          contentClass: 'italic',
        },
        dates: new Date(),
      };

      this.attributes = this.attributes.filter(function(x) {
        return x.key != "selectDate";
      });

      this.attributes.push(selectedDateMap);
    },
    getMonthAnswerHistoryDateList(dailyQuizId, searchDate){
      let params = {};
      params.dailyQuizId = dailyQuizId;
      params.searchDate = searchDate;

      apiClient.get('/api/daily-quiz/answer/days', {params:params}).then(data => {
        if(data.data.resData.length > 0){
          this.currentMonthAnswerDateList = data.data.resData;

          let completeDateMap = {
            key: 'completeMark',
            highlight: {
              color: 'red',
              fillMode: 'light',
              contentClass: 'italic',
            },
            dates: this.currentMonthAnswerDateList,
          };

          this.attributes = this.attributes.filter(function(x) {
            return x.key != "completeMark";
          });
          this.attributes.unshift(completeDateMap); // 선택한 날짜의 하이라이트가 우선으로 표시되기 위함
        }else{
          this.attributes = this.attributes.filter(function(x) {
            return x.key != "completeMark";
          });
        }
      })
    },
    daySelect($event){
      let selectedDateMap = {
        key: 'selectDate',
        highlight: {
          color: 'purple',
          fillMode: 'solid',
          contentClass: 'italic',
        },
        dates: new Date($event.id),
      };

      this.attributes = this.attributes.filter(function(x) {
        return x.key != "selectDate";
      });
      this.attributes.push(selectedDateMap);

      if(this.mode === 'S'){
        let param = {};
        param.searchDate = $event.id;
        param.mode = this.mode;
        this.getDailyQuizAnswerHistoryList(param);
      }else if(this.mode === 'R'){
        let param = {};
        param.searchDate = $event.id;
        param.mode = this.mode;
        this.getRandomQuizHistory(param);
      }
    },
    calendarUpdatePageEvent($event){
      let activeDailyQuizId = $('.swiper-slide.swiper-slide-active .daily_quiz_item input[name=dailyQuizId]').val();
      let updatePageMonth = new Date($event[0].id);

      this.getMonthAnswerHistoryDateList(activeDailyQuizId, this.$dateUtils.dateToString(updatePageMonth));
    },
    getDailyQuizAnswerHistoryList(param) {
      param.dailyQuizId = $('.swiper-slide.swiper-slide-active .daily_quiz_item input[name=dailyQuizId]').val();

      this.mode = param.mode;

      apiClient.get('/api/daily-quiz/answer/histories', {params: param}).then(data => {
        let answerHistoryItems = data.data.resData.answerHistoryItems;

        if (answerHistoryItems.length > 0) {
          this.$emit('setDailyQuizAnswerHistoryItemList', answerHistoryItems);
        } else {
          this.$emit('setDailyQuizAnswerHistoryItemList', []);
        }
      });
    },
    getRandomQuizHistory(param) {
      param.dailyQuizId = $('.swiper-slide.swiper-slide-active .daily_quiz_item input[name=dailyQuizId]').val();

      this.mode = param.mode;

      apiClient.get('/api/daily-quiz/answer/histories', {params: param}).then(data => {
        let resultData = data.data.resData;
        if (resultData != null) {
          this.$emit('setDailyQuizInfoItemList', resultData.randomQuizItems);
          this.$emit('setDailyQuizAnswerHistoryItemList', resultData.answerHistoryItems);
        } else {
          this.$emit('setDailyQuizInfoItemList', []);
          this.$emit('setDailyQuizAnswerHistoryItemList', []);
        }
      });
    }
  }
}
</script>

<style>
  @import "@/assets/main/manage/DailyQuizManage.css";


</style>