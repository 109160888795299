<template>
  <div ref="loading" class="loader_div">
    <div class="loader"></div>
  </div>
</template>


<script>
export default{
  name: 'Loader'
}
</script>
<style>
@import "@/assets/common/Loader.css";
</style>