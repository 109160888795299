<template>
  <div class="manage_menu_area">
    <div class="manage_menu depth1"><p>관리</p></div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchManageContent"><p>퀴즈 관리</p></div>
    </div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchDailyQuizManageContent"><p>일일 퀴즈 관리</p></div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import router from "@/utils/router";

export default {
  name: 'Manage',
  methods: {
    switchManageContent: function (){
      this.sidebarClose();
      router.push('/manage');
    },
    switchDailyQuizManageContent: function (){
      this.sidebarClose();
      router.push('/daily');
    },
    sidebarClose(){
      $(".category_detail").removeClass('active');
      $(".side_bar_overlay").removeClass('on');
      $(".side_bar").css('display', 'none');
    },
  }
}
</script>

<style scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/main/sidebar/sub/Manage.css";
</style>