<template>
  <div ref="loading_item" class="loader_item_div">
    <div class="loader_item"></div>
  </div>
</template>


<script>
export default{
  name: 'LoaderItem'
}
</script>
<style>
@import "@/assets/common/LoaderItem.css";
</style>