<template>
  <div class="main_area">
    <div class="top_bar">
      <div class="logo_img" @click="switchHomePage"></div>
      <button class="menu_icon" @click="sidebarOpen"/>
    </div>

    <div class="side_bar_overlay off" @click="sidebarClose"></div>
    <SideBar></SideBar>

    <div class="main_content_area">
      <RouterView/>
    </div>

    <div class="floating_btn_area" v-if="login">
      <div class="floating_btn" @click="subQuickBtnOpen"></div>

      <div v-if="!resFlag"></div>
      <div v-else-if="(dailyQuizId === null || dailyQuizId === 0)">
        <template v-if="!deleteRegMsg">
          <div class='floating_msg_daily red'>
            <p class="msg" >일일 퀴즈를 등록하세요!</p>
          </div>
          <div class='floating_msg_daily_bottom red'></div>
          <div class='floating_msg_del_btn' @click="deleteRegMsgFn">×</div>
        </template>
      </div>
      <div v-else>
        <template v-if="!dailyQuizTodayCheck">
          <template v-if="!deleteDailyMsg && resFlag">
            <div class='floating_msg_daily blue'>
              <p class="msg">일일 퀴즈를 수행하세요!</p>
            </div>
            <div class='floating_msg_daily_bottom blue'></div>
            <div class='floating_msg_del_btn' @click="deleteDailyMsgFn">×</div>
          </template>
        </template>
      </div>

      <div class="floating_btn_sub_area">
        <div class="floating_btn_sub daily" @click="openDailyQuizModal(dailyQuizId)"></div>
        <div class="floating_btn_sub write" @click="openWriteModal"></div>

        <div class="daily_today_complete" v-if="dailyQuizTodayCheck"></div>
      </div>
    </div>

    <Loader></Loader>
  </div>

  <WriteModal ref="writeModal" @closeWriteModal=closeWriteModal v-show="writeModalOn"/>
  <DailyQuizModal @closeDailyQuizModal=closeDailyQuizModal :dailyQuizId="dailyQuizId" :dailyQuizModalOn="dailyQuizModalOn" v-if="dailyQuizModalOn"></DailyQuizModal>
</template>

<script>

import SideBar from "@/components/main/sidebar/SideBar";
import Loader from "@/components/common/Loader";
import router from "@/utils/router";
import WriteModal from "@/components/modal/write/WriteModal.vue";
import DailyQuizModal from "@/components/modal/quiz/DailyQuizModal.vue";
import Footer from "@/components/layout/Footer.vue";
import "vue3-toastify/dist/index.css";
import axios from "axios";
import {toastFail, toastInfo} from "@/utils/toastUtils";

export default {
  name: 'Main',
  components: {Footer, DailyQuizModal, WriteModal, SideBar, Loader},
  data: () => ({
    quickBtnOpen : false,
    writeModalOn: false,
    dailyQuizModalOn: false,
    dailyQuizId : null,
    dailyQuizTodayCheck: false,
    login : false,
    deleteRegMsg : false,
    deleteDailyMsg : false,
    resFlag: false
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    },
    setDailyQuizId(){
      this.dailyQuizId = this.$store.state.dailyQuiz.dailyQuizId;
      return this.$store.state.dailyQuiz.dailyQuizId;
    },
    setDailyQuizTodayCheck(){
      this.dailyQuizTodayCheck = this.$store.state.dailyQuiz.dailyQuizTodayCheck;
      return this.$store.state.dailyQuiz.dailyQuizTodayCheck;
    },
  },
  watch:{
    isLoginCheck(){
      this.login = !!this.$store.state.auth.isLogin;
    },
    setDailyQuizId(){
      this.dailyQuizId = this.$store.state.dailyQuiz.dailyQuizId;
      this.resFlag = true;
    },
    setDailyQuizTodayCheck(){
      this.dailyQuizTodayCheck = this.$store.state.dailyQuiz.dailyQuizTodayCheck;
      this.resFlag = true;
    }
  },
  created() {
    if(this.$cookies.get("delete_reg_msg") !== null){
      this.deleteRegMsg = this.$cookies.get("delete_reg_msg");
    }

    if(this.$cookies.get("delete_daily_msg") !== null){
      this.deleteDailyMsg = this.$cookies.get("delete_daily_msg");
    }
  },
  methods: {
    sidebarOpen(){
      let sideBarAttr = $(".side_bar").css('display');

      if(sideBarAttr == 'block'){
        $(".side_bar").css('display', 'none');
        $(".side_bar_overlay").removeClass('on');
      }else{
        $(".side_bar").css('display', 'block');
        $(".side_bar_overlay").addClass('on');
      }
    },
    sidebarClose(){
      $(".side_bar_overlay").removeClass('on');
      $(".side_bar").css('display', 'none');
    },
    switchHomePage: function (){
      router.push('/');
    },
    subQuickBtnOpen: function(){
      if(!this.quickBtnOpen){
        this.quickBtnOpen = true;
        $(".floating_btn_area").addClass('open');
        $(".floating_btn_area").removeClass('close');
      }else{
        this.quickBtnOpen = false;
        $(".floating_btn_area").removeClass('open');
        $(".floating_btn_area").addClass('close');
      }
    },
    openWriteModal(){
      this.writeModalOn = true;
      this.$refs.writeModal.resetInputValue();
    },
    closeWriteModal: function (){
      this.writeModalOn = false;
    },
    openDailyQuizModal(dailyQuizId){
      if(dailyQuizId === 0){
        this.sidebarClose();
        router.push('/daily');

        return;
      }

      axios.get('/api/daily-quiz/check/' + dailyQuizId).then(data => {
        if(data.data.resData === 0) {
          this.$store.dispatch('setDailyQuizTodayCheck', false);
          this.dailyQuizId = dailyQuizId;
          this.dailyQuizModalOn = true;
        }else{
          this.$store.dispatch('setDailyQuizTodayCheck', true);
          toastInfo('오늘 일일 퀴즈는 이미 수행하였습니다.');
          return false;
        }
      });
    },
    closeDailyQuizModal: function (){
      this.dailyQuizModalOn = false;
    },
    deleteRegMsgFn(){
      this.deleteRegMsg = true;
      this.$cookies.set("delete_reg_msg",true,60 * 60);
    },
    deleteDailyMsgFn(){
      this.deleteDailyMsg = true;
      this.$cookies.set("delete_daily_msg",true,60 * 60);
    }
  }
}
</script>

<style>
  @import "@/assets/layout/Main.css";
  @import "@/resource/css/reset.css";
  @import "@/resource/css/default-style.css";
</style>