<template>
  <div class="category_area">
    <p class="label_category">카테고리</p>
    <template v-for="mainCategory in categoryArr">
      <div :class="'category depth1 id_' + mainCategory.categoryId" @click="openCategory(mainCategory.categoryId)">
        <p class="category_depth1_name">{{mainCategory.categoryName}}</p>
      </div>
      <template v-for="subCategory in mainCategory.subCategoryList">
        <div :class="'category depth2 idx_' + mainCategory.categoryId">
          <div :class="'category_detail id_' + subCategory.subCategoryId " @click="loadCategoryProblemList(subCategory.subCategoryId)">
            <p class="category_depth2_name">{{ subCategory.subCategoryName }}</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient";
import {mapActions} from "vuex";
import router from "@/utils/router";

export default {
  //created : 렌더링은 안돼있지만 vue 인스턴스가 생성되면서 동작하는 hook. virtual dom은 사용할 수 없지만 data에 접근 및 초기화 하거나 이벤트를 처리하는데 자주 이용 (부모 > 자식 순 동작)
  //mounted : vue 인스턴스가 dom에 mounted될때 동작하는 hook. 주로 dom에 접근하여 조작이 필요하거나 watch를 통해 부모의 데이터 변경을 감지하고자 할때 사용 (자식 > 부모 순 동작)
  name: 'Category',
  data:() => ({
    categoryArr: []
  }),
  created() {
    this.getCategory()
  },
  methods: {
    openCategory: function (cateId){

      if($(".category.depth1.id_" + cateId).hasClass('open')){
        $(".category.depth1.id_" + cateId).removeClass('open')

        $(".category.depth2.idx_" + cateId).each(function(){
          $(this).removeClass('open');
        })
      }else{
        $(".category.depth1.id_" + cateId).addClass('open');

        $(".category.depth2.idx_" + cateId).each(function(){
          $(this).addClass('open');
        })
      }
    },
    getCategory: function (){
      apiClient.get('/api/categories').then(data => {
        this.categoryArr = data.data.resData;
      });
    },
    loadCategoryProblemList(selectCategoryId){
      $(".category_detail").removeClass('active');
      $(".category_detail.id_" + selectCategoryId).addClass('active');

      this.sidebarClose();
      router.push('/quiz/' + selectCategoryId);
    },
    sidebarClose(){
      $(".side_bar_overlay").removeClass('on');
      $(".side_bar").css('display', 'none');
    }
  }
}
</script>

<style scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/main/sidebar/sub/Category.css";
</style>